@use "@jeffcarbine/premmio/public/styles/mixin";

@use "@jeffcarbine/premmio/public/components/alert/alert";
@use "@jeffcarbine/premmio/public/components/placeholder/placeholder";
@use "@jeffcarbine/premmio/public/components/modal/modal";

@use "@jeffcarbine/premmio/public/components/product/product";
@use "@jeffcarbine/premmio/public/components/paginator/paginator";

@use "partials/animations";
@use "partials/footer";

.modal {
  h1,
  h2 {
    font-size: var(--fs6);
  }

  img {
    width: 100%;
  }
}

* {
  overflow-anchor: none;
}

.main-banner {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  text-align: center;
  background: var(--accent-500);
  text-transform: uppercase;
  color: var(--dark-800);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}

.announcement {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: var(--accent-500);
  z-index: 20;

  span {
    button {
      font-size: var(--fs10);

      display: inline-block;
      margin-left: 0.5rem;
      background: none;
      border: none;
      text-decoration: underline;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

#toggles {
  background: var(--dark-900);
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
}

// shared components
.campaignArrows {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 20;
  height: 2rem;

  button {
    width: 2rem;
    height: 2rem;
    display: block;
    border: none;
    position: absolute;
    top: 50%;
    z-index: 15;
    background: transparent;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 2px;
      border-right: 0.25rem solid var(--campaignArrowColor);
      border-bottom: 0.25rem solid var(--campaignArrowColor);
      transition: all 0.25s ease;
      background: transparent;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
    }

    &::after {
      content: "";
      display: block;
      height: 100%;
      width: 0;
      transition: width 0.25s ease;
      background: var(--accent-500);
      position: absolute;
      z-index: 1;
      top: 0;
    }

    &.next {
      left: 0;
      transform: translateY(-50%);

      &::before {
        transform: translate(-25%, -50%) rotate(135deg);
      }

      &::after {
        left: 0;
      }
    }

    &.prev {
      right: 0;
      transform: translateY(-50%);

      &::before {
        transform: translate(-75%, -50%) rotate(-45deg);
      }

      &::after {
        right: 0;
      }
    }
  }
}

ol {
  li {
    list-style-type: decimal;
  }
}

[data-component="paginator"] {
  --button-background: var(--accent-500);
  --button-icon-color: var(--accent-on);
  --button-background-hover: var(--accent-600);
  --active-font-weight: 900;
  --link-color: var(--foreground-900);
}

@media only screen and (min-width: mixin.$breakpoint-md) {
  .campaignArrows {
    button {
      width: 4rem;
      height: 4rem;

      &::before {
        width: 1.25rem;
        height: 1.25rem;
      }

      &:hover {
        &::before {
          border-color: var(--dark-900);
        }

        &::after {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: mixin.$breakpoint-lg) {
  body {
    &.offset {
      section {
        padding: 8rem !important;
      }
    }
  }
}
